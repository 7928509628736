*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-weight: normal;
  text-align: left;
  background: #000225;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 100vh;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
}
button {
  border: none;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar .logo img {
  max-width: 170px;
  width: 100%;
}
.logo {
  text-align: center;
}
.website-logo {
  margin-top: 20px;
}
.sidebar {
  width: 227px;
  max-width: 252px;
  background: linear-gradient(
    180deg,
    rgb(16 16 73) 0%,
    rgb(5 10 87 / 30%) 36%,
    rgb(9 16 121) 100%
  );
  position: fixed;
  height: 100%;
  top: 0;
}

.user-information {
  border-radius: 20px;
  background: #1730ad45;
  margin: 20px 20px 0;
  overflow: hidden;
}

.userwrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 0;
  line-height: 15px;
}
.c2a-depo-withdraw {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.c2a-depo-withdraw button {
  padding: 0;
  background: red;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  width: 50%;
}
.depo-btn {
  background: radial-gradient(
    52.78% 52.78% at 50% 50%,
    #ffffff 0%,
    #f5dd00 100%
  ) !important;
  box-shadow: 0px 0px 3px #ffe600;
  border-radius: 0px 0px 0px 10px;
  color: #00053a !important;
}

.withdraw-btn {
  background: radial-gradient(
    52.78% 52.78% at 50% 50%,
    #0a137a 0%,
    #00053a 100%
  ) !important;
  box-shadow: 0px 0px 3px #004987;
  border-radius: 0px 0px 10px 0px;
  color: #fff !important;
}
span.userID {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
span.userBalance {
  color: #f6dd01;
  font-size: 17px;
  font-weight: 700;
}
.slots {
  height: 80px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  font-weight: 600;
  text-transform: uppercase;
  background-position: center;
}
.primary-menu-list {
  margin-top: 30px;
}
.primary-menu-list a {
  color: #fff;
  cursor: pointer;
}
.navarea-promotions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-items: flex-end;
}

.main {
  width: 100%;
  max-width: 1300px;
  padding-top: 30px;

  margin: 0 auto;
  height: 100%;
  text-align: right;
  color: #fff;
}
.footer {
  width: 100%;
  max-width: 1300px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
  height: 100%;
  text-align: right;
  color: #fff;
}

.promotion {
  width: 100%;
  max-width: 1300px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
  height: 100%;
  text-align: right;
  color: #fff;
}
.featured-slot {
  width: 100%;
  max-width: 1300px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
}
.slot-provider-list ul {
  display: flex;
  padding: 0 20px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  gap: 20px;
  background: #ffffff12;
}
.h2-text-style {
  line-height: 20px;
  width: 15%;
  text-transform: uppercase;
}

.h2-text-style span {
  color: #f5dd00;
  font-weight: bold;
}
.h2-text-style h2 {
  color: #fff;
}
.provider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
}
.yellow-btn {
  background: radial-gradient(
    52.78% 52.78% at 50% 50%,
    #ffffff 0%,
    #f5dd00 100%
  ) !important;
  box-shadow: 0px 0px 3px #ffe600;
  color: #00053a !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 0;
  border-radius: 50px;
  width: 150px;
  padding-block: 10px;
}

.featured-games ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.featured-slot ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.featured-games ul li img {
  width: 100%;
  max-width: 200px;
}
.featured-slot img {
  width: 100%;
  max-width: 250px;
}
